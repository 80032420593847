import { HexString } from "@nested-finance/sdk/web";
import { RecoilRoot } from 'recoil';
import { render } from "react-dom";
import "./index.css";
import { Playground } from "./playground";
import { isStaging, useHash, useNet } from "./utils";
import { Welcome } from "./Welcome";
import { ToastContainer } from "react-toastify";

import 'react-toastify/dist/ReactToastify.css';

if (isStaging) {
  console.log("Staging mode");
  (window as any).useMassSdkStatingContracts = true;
}

declare var ethereum: any;


function App() {
  const [hash] = useHash();

  const net = useNet();

  return (
    <div className="h-screen w-screen overflow-auto bg-home-bg text-white p-6">
      {!hash || !net ? (
        <Welcome />
      ) : (
        <Playground
          vaultAddress={hash.replace("#", "") as HexString}
          net={net}
        />
      )}
      <ToastContainer position="bottom-center" theme="dark" newestOnTop />
    </div>
  );
}

render(<RecoilRoot><App /></RecoilRoot>, document.getElementById("root"));
