import { useEffect, useState } from "react";
import {
  CompilationResult,
  DocTree,
  Editor,
  PlaygroundCfg,
  fns,
} from "./editor";
import { Card } from "flowbite-react";
import Success from "./Success.svg";
import Loading from "./Loading.svg";
import Error from "./Error.svg";
import Copy from "./Copy.svg";
import { useSetRecoilState } from "recoil";
import { Btn } from "./Btn";
import Arrow from "./Arrow.svg";
import {
  isAllowed,
  useDebounce,
  useMyAddress,
  useNet,
  useWalletClient,
  waitForGelatoTxId,
  waitForSchedulerTask,
} from "./utils";
import {
  CompiledVaultOperation,
  decompileCalldataToCode,
  GaslessTx,
  Gasless712Tx,
  HexString,
  Tx,
  VaultConfig,
  fetchVaultOwner,
  RequiredSignature,
  DataToSign,
  GaslessBundledTxs,
} from "@nested-finance/sdk/web";
import { Simulation, Simulator } from "./Simulator";
import { allCollapsedAtom } from "./atom";

import { toast } from "react-toastify";
import { GasTankerBalance } from "./GasTankerBalance";
import { User } from "./User";

type DecompilationResult =
  | { type: "decompiling" }
  | { type: "decompiled"; code: string }
  | { type: "decompilation failed"; error: string };

export function Playground(props: PlaygroundCfg & { initialCode?: string }) {
  const [status, setStatus] = useState<CompilationResult | DecompilationResult>(
    { type: "compiling" }
  );
  const [bytecode, setBytecode] = useState<CompiledVaultOperation | null>(null);
  const [code, setCode] = useState(
    localStorage.getItem("code") ?? props.initialCode
  );
  const [simulation, setSimulation] = useState<Simulation | null>(null);
  const [vaultOwner, setVaultOwner] = useState<HexString | "error" | null>(
    null
  );
  const [redetect, setRedetect] = useState(0);
  const setCollapseAll = useSetRecoilState(allCollapsedAtom);

  useEffect(() => {
    localStorage.setItem("code", code ?? "");
  }, [code]);

  const net = useNet();
  const myAddress = useMyAddress();
  const walletClient = useWalletClient();

  // fetch vault owner
  useEffect(() => {
    (async () => {
      if (!myAddress) {
        return;
      }

      try {
        const owner = await fetchVaultOwner(props.net.rpc, props.vaultAddress);
        setVaultOwner(owner);
      } catch (e) {
        console.error("Failed to detect vault owner", e);
        setVaultOwner("error");
        setTimeout(() => setRedetect(redetect + 1), 1000);
      }
    })();
  }, [props.vaultAddress, net, myAddress, redetect]);

  // when compiled, then update bytecode
  useEffect(() => {
    if (status.type === "compiled") {
      setBytecode(status.result);
    }
  }, [status]);

  const doDecompile = async () => {
    if (
      !bytecode ||
      (status.type === "compiled" &&
        status.result.tx?.data === bytecode.tx?.data) ||
      !bytecode.tx
    ) {
      return;
    }
    const bcTxData = bytecode.tx.data;
    setStatus({ type: "decompiling" });
    const cfg: VaultConfig = {
      vaultAddress: props.vaultAddress,
      rpcUrl: props.net.rpc,
    };
    try {
      const { code } = await decompileCalldataToCode(cfg, bcTxData);

      setCode(code);
      setStatus({ type: "decompiled", code });
    } catch (e) {
      setStatus({ type: "decompilation failed", error: (e as any).message });
    }
  };

  const doExecute = async () => {
    if (!bytecode || !myAddress || !net || !walletClient) {
      return;
    }

    // for (const bytecode.)
    const execute = async (tx: Tx) => {
      await toast.promise(
        walletClient.sendTransaction({
          account: myAddress,
          chain: net.chain,
          to: tx.to,
          value: tx.value,
          data: tx.data,
        }),
        {
          pending: "Signing transaction...",
          success: {
            render({ data }) {
              return (
                <>
                  <div>Transaction sent:</div>
                  <a href={`${net.explorer}/tx/${data}`} target="_blank">
                    view on explorer
                  </a>
                </>
              );
            },
          },
          error: "User rejected the request",
        }
      );
    };

    const signTypedData = async (dataToSign: DataToSign) => {
      const signature = await toast.promise(
        walletClient.signTypedData({
          account: myAddress,
          types: dataToSign.types,
          domain: dataToSign.domain,
          message: dataToSign.message,
          primaryType: dataToSign.primaryType,
        }),
        {
          pending: "Signing message...",
          error: "User rejected the request",
        }
      );

      return signature;
    };

    const executeSignature = async (s: RequiredSignature) => {
      const signature = await signTypedData(s.dataToSign);

      await toast.promise(s.execute(signature), {
        pending: "Sending signed message...",
        success: "Signed message sent",
        error: "Error when sending signed message",
      });
    };

    const executeGasless = async (tx: GaslessTx) => {
      const signature = await signTypedData(tx.dataToSign);

      await toast.promise(
        tx.execute(signature).then((taskId) => waitForGelatoTxId(taskId)),
        {
          pending: "Sending gasless transaction...",
          success: {
            render({ data }) {
              return (
                <>
                  <div>Transaction sent:</div>
                  <a href={`${net.explorer}/tx/${data}`} target="_blank">
                    view on explorer
                  </a>
                </>
              );
            },
          },
          error: {
            render({ data }) {
              return (
                <>
                  <div>Error when sending gasless transaction:</div>
                  <div>{(data as any).message}</div>
                </>
              );
            },
          },
        }
      );
    };

    const executeGasless712 = async (tx: Gasless712Tx) => {
      const signature = await signTypedData(tx.dataToSign);

      const gaslessTx = await tx.buildGaslessTx(signature);

      await executeGasless(gaslessTx);
    };

    const executeGaslessBundledTxs = async (tx: GaslessBundledTxs) => {
      const signature = await signTypedData(tx.dataToSign);

      const taskIds = await toast.promise(tx.schedule(signature), {
        pending: "Scheduling transactions...",
        success: "Transactions scheduled",
        error: "Error when scheduling signed transactions",
      });

      for (const taskId of taskIds) {
        toast.promise(
          waitForSchedulerTask(taskId as HexString),
          {
            success: {
              render({ data }) {
                return (
                  <>
                    <div>Transaction executed:</div>
                    <a href={`${net.explorer}/tx/${data}`} target="_blank">
                      view on explorer
                    </a>
                  </>
                );
              },
            },
          },
          { autoClose: false, closeOnClick: true }
        );
      }
    };

    // execute required approves
    for (const a of bytecode.requiredApproves) {
      if (
        !(await isAllowed(
          a.token,
          myAddress,
          bytecode.vaultAddress,
          a.knownAmount?.amount
        ))
      ) {
        await execute(a.knownAmount?.performApprove ?? a.performApproveMax);
      }
    }

    // execute tx
    if (bytecode.tx) {
      await execute(bytecode.tx);
    }

    // execute tx gasless
    if (bytecode.txGasless) {
      await executeGasless(bytecode.txGasless);
    }

    // execute tx gasless712
    if (bytecode.txGasless712) {
      await executeGasless712(bytecode.txGasless712);
    }

    // execute tx gasless bundled
    if (bytecode.txGaslessBundled) {
      await executeGaslessBundledTxs(bytecode.txGaslessBundled);
    }

    // execute required signatures
    for (const s of bytecode.requiredSignatures) {
      await executeSignature(s);
    }
  };

  const doCopyCalldata = () => {
    if (bytecode?.tx) {
      navigator.clipboard.writeText(bytecode.tx.data);
    }
  };

  const doSimulate = () => {
    if (vaultOwner === "error" || !bytecode || !vaultOwner) {
      return;
    }
    setSimulation({
      sim: bytecode,
      from: vaultOwner,
      to: props.vaultAddress,
      value: "0x00",
    });
  };

  const isOwner = !!myAddress && !!vaultOwner && vaultOwner === myAddress;

  const execs = [];
  const alen = status.type === "compiled" && status.approvesRequired.length;
  if (alen) {
    execs.push(alen === 1 ? "1 approval" : `${alen} approvals`);
  }
  if (bytecode?.tx) {
    execs.push("vault transaction");
  }
  const siglen =
    (bytecode?.txGasless ? 1 : 0) +
    (bytecode?.txGasless712 ? 2 : 0) +
    (bytecode?.txGaslessBundled ? 1 : 0) +
    (bytecode?.requiredSignatures.length ?? 0);
  if (siglen) {
    execs.push(siglen === 1 ? "1 signature" : `${siglen} signatures`);
  }

  const executeWhat = execs.join(" + ");

  return (
    <div className="w-full h-full flex gap-6">
      <div className="flex flex-col p-6 gap-6 w-full rounded-3xl bg-surface">
        <div className="flex flex-col gap-6 h-2/3">
          <div className="flex justify-between items-center">
            <span className="text-xl font-youth font-medium">Code</span>
            <div className="flex gap-2">
              <GasTankerBalance vaultAddress={props.vaultAddress} />
              <User />
            </div>
          </div>
          <Editor
            code={code ?? ""}
            myAddress={myAddress}
            codeChange={setCode}
            {...props}
            onStatusChange={setStatus}
            error={status.type === "compilation failed" ? status : null}
          />
        </div>
        <div className="flex items-center gap-2 justify-center">
          <span className="text-5xl">
            <img
              src={Arrow}
              className={
                status.type.startsWith("decompil") ? "rotate-180" : "rotate-0"
              }
            />
          </span>
          <div
            className={`flex p-3 rounded-xl items-center gap-1.5 bg-surface-muted text-base font-medium ${
              status.type.includes("compiling")
                ? "text-accent"
                : status.type.includes("failed")
                ? "text-error"
                : "text-success"
            }`}
          >
            {status.type === "compiling" && (
              <>
                <img src={Loading} className="w-4 h-4 animate-spin" /> Compiling
              </>
            )}
            {status.type === "compiled" && (
              <>
                <img src={Success} /> Compiled & verified
              </>
            )}
            {status.type === "compilation failed" && (
              <>
                <img src={Error} /> Compilation failed: {status.error}
              </>
            )}
            {status.type === "decompiling" && (
              <>
                <img src={Loading} className="w-4 h-4 animate-spin" />{" "}
                Decompiling
              </>
            )}
            {status.type === "decompiled" && (
              <>
                <img src={Success} /> Decompiled
              </>
            )}
            {status.type === "decompilation failed" && (
              <>
                <img src={Error} /> Decompilation failed: {status.error}
              </>
            )}
          </div>
        </div>
        <form className="flex flex-col gap-4">
          <textarea
            value={bytecode?.tx?.data ?? ""}
            onChange={(e) =>
              setBytecode({
                source: null!,
                vaultAddress: null!,
                tx: {
                  to: null!,
                  value: 0n,
                  data: e.target.value as HexString,
                },
                txGasless: null,
                txGasless712: null,
                txGaslessBundled: null,
                requiredApproves: [],
                requiredSignatures: [],
                metadata: [],
              })
            }
            rows={4}
            className="rounded-2xl text-sm font-normal p-3 border-0 focus:ring-0 bg-surface-variant-muted text-white placeholder:text-font-variant"
            placeholder="Paste calldata here to decompile it..."
            required
          ></textarea>
          <div className="flex items-center gap-4">
            <div className="flex items-center flex-1 gap-4">
              <Btn
                disabled={!isOwner || !net || props.net !== net}
                onClick={doExecute}
                size="small"
              >
                Execute {executeWhat}
              </Btn>
              <Btn
                variant="secondary"
                disabled={!net || props.net !== net}
                onClick={doSimulate}
                size="small"
              >
                Simulate
              </Btn>
              {!vaultOwner ? (
                <div className="text-sm font-medium">Detecting owner...</div>
              ) : (
                !isOwner && (
                  <div className="text-red-500 text-sm font-medium">
                    {vaultOwner === "error"
                      ? "Failed to detect vault owner"
                      : "⚠ You are not the owner of this vault. Simulations will run as the actual owner"}
                  </div>
                )
              )}
            </div>
            <Btn
              variant="secondary"
              disabled={!net || props.net !== net || !bytecode?.tx}
              onClick={doDecompile}
              size="small"
            >
              Decompile
            </Btn>
            <button
              onClick={doCopyCalldata}
              className="flex items-center justify-center rounded-full bg-surface-muted p-2"
            >
              <img src={Copy} className="w-4 h-4" />
            </button>
          </div>
        </form>
        {simulation && net && vaultOwner && (
          <>
            <div className="text-light text-center">
              <span className="text-5xl">↓</span>
              <div className="m-5 w-[200px] inline-block text-left">
                Simulation
              </div>
            </div>
            <Card className="p-5">
              <Simulator tx={simulation} net={net} />
            </Card>
          </>
        )}
      </div>
      <div className="flex flex-col p-6 gap-6 min-w-[512px] rounded-3xl bg-surface overflow-auto hide-scrollbars">
        <div className="flex justify-between items-center sticky top-0">
          <span className="text-xl font-youth font-medium">
            Available functions
          </span>
          <button
            onClick={() => setCollapseAll((prev) => prev + 1)}
            className="py-1.5 px-4 rounded-2xl text-sm font-medium bg-surface-muted"
          >
            Collapse all
          </button>
        </div>
        <div className="flex flex-col gap-4">
          {fns.map((n) => (
            <DocTree key={n.name} node={n} />
          ))}
        </div>
      </div>
    </div>
  );
}
