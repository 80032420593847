import { Button } from "flowbite-react";
import { useMyAddress, useNet } from "./utils";
import { HexString } from "@nested-finance/sdk/web";

export function User() {
  const net = useNet();
  const myAddress = useMyAddress();

  const renderMyAddress = (a: HexString) => `${a.slice(0, 6)}...${a.slice(-4)}`

  return net && myAddress && (
    <Button className="bg-surface-muted dark:focus:ring-0">
      <img src={net.icon} className="w-5 mr-2" />
      {renderMyAddress(myAddress)}
    </Button>
  )
}
