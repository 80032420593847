import { Button, Popover } from "flowbite-react";
import GasTank from "./GasTank.svg";
import ErrorIcon from "./Error.svg";
import { massApiUrl, useMyAddress } from "./utils";
import { HexString } from "@nested-finance/sdk/web";
import { useEffect, useState } from "react";

type GasTankerUserBalance = {
  balance: number;
  deposited: number;
  pendingSpent: number;
  spent: number;
  isActivated: boolean;
};

export function GasTankerBalance(props: { vaultAddress: HexString }) {
  const myAddress = useMyAddress();

  const [balance, setBalance] = useState<GasTankerUserBalance | null>(null);

  const fetchGasTankerBalance = async (userAddress: HexString) => {
    const b = await fetch(
      `${massApiUrl}/compiler/gas-tanker/user-balance?userId=${userAddress}`
    ).then((res) => res.json());

    setBalance({
      balance: b.balance / 10 ** 6,
      deposited: b.deposited / 10 ** 6,
      pendingSpent: b.pendingSpent / 10 ** 6,
      spent: b.spent / 10 ** 6,
      isActivated: true,
    });
  };

  useEffect(() => {
    if (!myAddress) return;

    fetchGasTankerBalance(myAddress);
    const interval = setInterval(() => {
      fetchGasTankerBalance(myAddress);
    }, 5000); // 5s

    return () => clearInterval(interval);
  }, [myAddress]);

  return (
    myAddress &&
    balance && (
      <Popover
        placement="bottom"
        trigger="hover"
        arrow={false}
        className="absolute z-20 inline-block w-max max-w-[100vw] bg-white outline-none border border-gray-200 shadow-sm dark:border-gray-600 dark:bg-gray-800"
        content={
          <div className="w-64 text-sm dark:text-gray-400">
            <div className="border-b border-gray-200 bg-gray-100 px-3 py-2 dark:border-gray-600 dark:bg-surface">
              <h3 className="font-semibold text-gray-900 dark:text-white">
                Gas tanker
              </h3>
            </div>
            <div className="dark:bg-surface">
              {balance.isActivated ? (
                <>
                  <div className="px-3 py-2">
                    <p>Deposited: {balance.deposited}</p>
                    <p>Spent: {balance.spent}</p>
                    <p>Pending spent: {balance.pendingSpent}</p>
                  </div>
                  <div className="border-t border-gray-200 dark:border-gray-600 px-3 py-2">
                    <p>
                      Pay fee in USDC by adding{" "}
                      <code
                        className="bg-surface-muted"
                        style={{ color: "#5497CE" }}
                      >
                        #gasless;
                      </code>{" "}
                      on the top of your code.
                    </p>
                  </div>
                </>
              ) : (
                <div className="border-t border-gray-200 dark:border-gray-600 px-3 py-2">
                  <div className="flex gap-1.5 pb-2">
                    <img src={ErrorIcon} /> Your gas tanker is not activated.
                  </div>
                  <p>
                    To pay fee in USDC, you need to activate it first by
                    executing:
                  </p>
                  <code className="bg-surface-muted">
                    gasTanker.activate();
                  </code>
                </div>
              )}
            </div>
          </div>
        }
      >
        <Button className="bg-surface-muted dark:focus:ring-0">
          {balance.isActivated ? (
            <>
              <img src={GasTank} className="w-5 mr-2" />
              {balance.balance} USDC
            </>
          ) : (
            <img src={GasTank} className="w-4 h-4" />
          )}
        </Button>
      </Popover>
    )
  );
}
