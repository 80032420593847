import React, { useState } from "react";
import search from "./Search.svg";
import { Btn } from "./Btn";

// https://flowbite.com/docs/forms/input-field/
export function ButtonTxt({
  placeholder,
  onValidate,
}: {
  onValidate: (text: string) => any;
  placeholder: string;
}) {
  const [text, setText] = useState("");
  return (
    <form className="relative w-full">
      <input
        id="search-dropdown"
        placeholder={placeholder}
        className="border-none focus:ring-0 w-full bg-surface-variant-muted rounded-xl py-2 pl-4 pr-[52px] placeholder:text-font-variant text-lg font-normal"
        value={text}
        onChange={(e) => setText(e.target.value)}
      />
      <Btn
        className="absolute rounded-full right-2 inset-y-1.5 flex items-center !px-2 h-8 w-8 flex-shrink-0"
        onClick={() => onValidate(text)}
      >
        <img src={search} className="h-4 w-4" />
      </Btn>
    </form>
  );

  return (
    <form>
      <div className="flex">
        <div className="relative w-full">
          <input
            value={text}
            onChange={(e) => setText(e.target.value)}
            type="search"
            id="search-dropdown"
            className="block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-lg border-l-gray-100 border-l-2 border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-blue-500"
            placeholder={placeholder}
            required
          />
          <button
            onClick={() => onValidate(text)}
            type="submit"
            className="absolute top-0 right-0 p-2.5 text-sm font-medium text-white bg-blue-700 rounded-r-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            <svg
              aria-hidden="true"
              className="w-5 h-5"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              ></path>
            </svg>
          </button>
        </div>
      </div>
    </form>
  );
}
