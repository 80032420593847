import React, { useEffect, useState } from "react";
import { Children, Cls } from "./interfaces";

// https://flowbite.com/docs/components/buttons/
export function Btn({
  className,
  children,
  disabled,
  variant = "primary",
  size = "default",
  onClick,
}: {
  disabled?: boolean;
  onClick: () => any;
  variant?: "primary" | "secondary";
  size?: "default" | "small";
} & Children &
  Cls) {
  const [status, setStatus] = useState<
    "none" | "loading" | "disabled" | "error"
  >(disabled ? "disabled" : "none");

  useEffect(() => {
    setStatus(disabled ? "disabled" : "none");
  }, [disabled]);

  const doClick = async () => {
    if (disabled || status === "loading" || status === "disabled") {
      return;
    }
    setStatus("loading");
    try {
      await onClick();
      setStatus(disabled ? "disabled" : "none");
    } catch (e) {
      console.error(e);
      setStatus("error");
    }
  };

  return (
    <button
      onClick={doClick}
      disabled={status === "disabled" || status === "loading"}
      className={
        className +
        " rounded-3xl font-youth font-black " +
        (variant === "primary" ? " bg-accent text-font-on-accent" : " text-accent bg-surface-muted") +
        (status === "disabled" || status === "loading" ? " bg-surface-muted text-font-disabled" : "") +
        (size === "default"
          ? " py-3 px-6 text-base"
          : " py-1.5 px-4 text-sm leading-6")
      }
    >
      {children}
    </button>
  );

  // const cls = (() => {
  //     switch (status) {
  //         case 'none':
  //         case 'error':
  //             return 'text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700';
  //             // return 'text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 text-center';
  //         case 'loading':
  //         case 'disabled':
  //             let ret = 'py-2.5 px-5 mr-2 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:outline-none focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 text-center';
  //             if (status === 'disabled') {
  //                 ret +=' cursor-not-allowed';
  //             }
  //             return ret;
  //     }
  // })();

  // return <button disabled={status === 'disabled'} type="button" onClick={doClick} className={cls}>
  //     {status === 'loading' && <Spinner />}
  //     {children}
  // </button>
}
