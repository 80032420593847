import { atom } from "recoil";

export const allCollapsedAtom = atom<number>({
  key: 'collasped',
  default: 0,
});

export const editorInjectorAtom = atom<string>({
  key: 'editorInjector',
  default: '',
  effects: [
    ({ onSet, setSelf }) => {
      onSet(() => {
        setTimeout(() => {
          // reset injected value after set
          setSelf('')
        }, 10);
      })
    }
  ]
});