import { Chain, arbitrum, avalanche, base, bsc, mainnet, optimism, polygon } from 'viem/chains'

export const chainIds: Record<string, Net> = {
    '0x89': {
        name: 'Polygon',
        rpc: `https://polygon-mainnet.g.alchemy.com/v2/SFTjd__zMX-1yKngYZdyvEZgyRWIII2g`,
        explorer: 'https://polygonscan.com',
        icon: 'https://cdn.instadapp.io/avocado/networks/137.svg',
        apiId: 'poly',
        chain: polygon
    } as const,
    '0x1': {
        name: 'Ethereum',
        rpc: `https://eth-mainnet.g.alchemy.com/v2/tk-O9JAUX7-anRBtT1unevAhT06DwUaw`,
        explorer: 'https://etherscan.io',
        icon: 'https://cdn.instadapp.io/avocado/networks/1.svg',
        apiId: 'eth',
        chain: mainnet
    } as const,
    '0xa4b1': {
        name: 'Arbitrum',
        rpc: `https://arb-mainnet.g.alchemy.com/v2/tk-O9JAUX7-anRBtT1unevAhT06DwUaw`,
        explorer: 'https://arbiscan.io',
        icon: 'https://cdn.instadapp.io/avocado/networks/42161.svg',
        apiId: 'arbi',
        chain: arbitrum
    },
    '0xa86a': {
        name: 'Avalanche',
        rpc: `https://api.avax.network/ext/bc/C/rpc`,
        explorer: 'https://snowtrace.io',
        icon: 'https://cdn.instadapp.io/avocado/networks/43114.svg',
        apiId: 'avax',
        chain: avalanche
    },
    '0xa': {
        name: 'Optimism',
        rpc: `https://opt-mainnet.g.alchemy.com/v2/tk-O9JAUX7-anRBtT1unevAhT06DwUaw`,
        explorer: 'https://optimistic.etherscan.io',
        icon: 'https://cdn.instadapp.io/avocado/networks/10.svg',
        apiId: 'opti',
        chain: optimism
    },
    '0x38':  {
        name: 'BNB Chain',
        rpc: 'https://bsc-dataseed.binance.org/',
        explorer: 'https://bscscan.com',
        icon: 'https://cdn.instadapp.io/avocado/networks/56.svg',
        apiId: 'bsc',
        chain: bsc
    },
    '0x2105': {
        name: 'Base',
        rpc: 'https://base-mainnet.g.alchemy.com/v2/tk-O9JAUX7-anRBtT1unevAhT06DwUaw',
        explorer: 'https://basescan.org',
        apiId: 'base',
        icon: 'https://cdn.instadapp.io/avocado/networks/8453.svg',
        chain: base
    }
};

export interface Net {
    name: string;
    rpc: string;
    explorer: string;
    icon: string;
    apiId: string;
    chain: Chain
}
