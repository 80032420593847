import {
  DataToSign,
  HexString,
  computeExpectedVaultAddress,
  createVault,
  createVaultAndExecute,
  fetchVaultOwner,
} from "@nested-finance/sdk/web";
import { Btn } from "./Btn";
import "./index.css";
import { ButtonTxt } from "./Txt";
import {
  massApiUrl,
  useHash,
  useMyAddress,
  useNet,
  useWalletClient,
  waitForConfirmedTx,
  waitForSchedulerTask,
} from "./utils";
import logo from "./mass.svg";
import { toast } from "react-toastify";
import { Label, Radio } from "flowbite-react";
import { useEffect, useState } from "react";
import { WalletClient, keccak256 } from "viem";

export function Welcome() {
  const [_, setHash] = useHash();

  const net = useNet();

  const myAddress = useMyAddress();

  const walletClient = useWalletClient();

  const [gasTankerBalance, setGasTankerBalance] = useState(0);

  const [useGasWallet, setUseGasWallet] = useState(true);

  useEffect(() => {
    if (!myAddress) {
      return;
    }

    try {
      fetch(
        `${massApiUrl}/compiler/gas-tanker/user-balance?userId=${myAddress}`
      )
        .then((res) => res.json())
        .then((b) => setGasTankerBalance(b.balance / 10 ** 6));
    } catch (e) {}
  }, [myAddress]);

  const doCreateNewVault = async () => {
    if (!net || !myAddress || !walletClient) {
      return;
    }

    const mainVaultSalt = keccak256(myAddress);
    const mainVaultAddress = await computeExpectedVaultAddress({
      creatorAddress: myAddress,
      rpcUrl: net.rpc,
      salt: mainVaultSalt,
    });

    let salt: HexString | undefined = undefined;
    try {
      await fetchVaultOwner(net.rpc, mainVaultAddress);
    } catch (e) {
      // main vault does not exist
      salt = mainVaultSalt;
    }

    if (useGasWallet) {
      const { tx, vaultAddress } = await createVault({
        creatorAddress: myAddress,
        rpcUrl: net.rpc,
        salt,
      });

      if (tx) {
        const txId = await toast.promise(
          walletClient.sendTransaction({
            account: myAddress,
            chain: net.chain,
            to: tx.to,
            value: tx.value,
            data: tx.data,
          }),
          {
            pending: "Signing transaction...",
            error: "User rejected the request",
          }
        );

        await toast.promise(waitForConfirmedTx(txId, net), {
          pending: "Sending transaction...",
          success: {
            render() {
              return (
                <>
                  <div>Vault created 🚀</div>
                  <a href={`${net.explorer}/tx/${txId}`} target="_blank">
                    view on explorer
                  </a>
                </>
              );
            },
          },
          error:
            "Transaction is not yet confirmed, please refresh the page later",
        });

        gotoAddress(vaultAddress);
      }
    } else {
      const { dataToSign, buildTx, vaultAddress } = await createVaultAndExecute(
        {
          creatorAddress: myAddress,
          rpcUrl: net.rpc,
          salt,
        },
        "gasTanker.activate();"
      );

      const signature = await signTypedData(
        walletClient,
        myAddress,
        dataToSign
      );

      const tx = buildTx(signature);

      const taskId = await toast.promise(
        async () => {
          const res = await fetch(
            `${massApiUrl}/compiler/create-vault-and-execute`,
            {
              method: "POST",
              headers: { "content-type": "application/json" },
              body: JSON.stringify({
                ...tx,
                value: "0x" + tx.value.toString(16),
                chainId: net.chain.id,
              }),
            }
          );
          if (!res.ok) {
            throw new Error("Failed to create vault");
          }
          const { taskId } = await res.json();
          return taskId;
        },
        {
          pending: "Signing transaction...",
          error: "Failed to create vault 💥",
        }
      );

      await toast.promise(waitForSchedulerTask(taskId, true), {
        pending: "Sending transaction...",
        success: {
          render({ data }) {
            return (
              <>
                <div>Vault created 🚀</div>
                <a href={`${net.explorer}/tx/${data}`} target="_blank">
                  view on explorer
                </a>
              </>
            );
          },
        },
        error:
          "Transaction is not yet confirmed, please refresh the page later",
      });

      gotoAddress(vaultAddress);
    }
  };

  const signTypedData = async (
    walletClient: WalletClient,
    account: HexString,
    dataToSign: DataToSign
  ) => {
    const signature = await toast.promise(
      walletClient.signTypedData({
        account,
        types: dataToSign.types,
        domain: dataToSign.domain,
        message: dataToSign.message,
        primaryType: dataToSign.primaryType,
      }),
      {
        pending: "Signing message...",
        error: "User rejected the request",
      }
    );

    return signature;
  };

  const gotoAddress = (address: string) => {
    setHash(address);
  };

  return (
    <div className="flex flex-col h-full w-full items-center justify-center">
      <div className="flex flex-col p-8 bg-surface rounded-3xl gap-6">
        <div className="flex flex-col items-center gap-6">
          <img src={logo} />
          <div className="text-4xl leading-[48px] font-youth font-black">
            Mass SDK Playground
          </div>
        </div>
        <div className="flex justify-center gap-6">
          {gasTankerBalance > 1 && (
            <>
              <div className="text-l font-youth font-black">Gas source</div>
              <div className="flex items-center gap-2">
                <Radio
                  id="use-wallet"
                  checked={useGasWallet}
                  onChange={() => setUseGasWallet(true)}
                />
                <Label htmlFor="use-wallet">Wallet</Label>
              </div>
              <div className="flex items-center gap-2">
                <Radio
                  id="use-gas-tanker"
                  checked={!useGasWallet}
                  onChange={() => setUseGasWallet(false)}
                />
                <Label htmlFor="use-gas-tanker">Gas tanker</Label>
              </div>
            </>
          )}
        </div>
        <div className="flex flex-col items-center gap-4">
          <Btn className="w-full" onClick={doCreateNewVault}>
            Create a new vault
          </Btn>
          <div className="flex items-center gap-2 w-full">
            <div className="bg-outline rounded-sm h-px flex-1" />
            <span className="text-base font-normal text-font-variant">Or</span>
            <div className="bg-outline rounded-sm h-px flex-1" />
          </div>
          <ButtonTxt
            onValidate={gotoAddress}
            placeholder="You vault address (0x....)"
          ></ButtonTxt>
        </div>
      </div>
    </div>
  );
}
