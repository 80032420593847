import React, { FC, useEffect, useState } from "react";
import MonacoEditor, { MonacoEditorProps } from "react-monaco-editor";
import dedent from "dedent";
import { MarkerSeverity, Range as MonacoRange, editor } from "monaco-editor";
import type * as monaco from "monaco-editor";
import _package from "./Package.svg";
import _function from "./Function.svg";
import caret from "./Caret.svg";

import { isAllowed, nil, toLookup, useElementSize } from "./utils";
import {
  CompiledVaultOperation,
  callVault,
  HexString,
  VaultConfig,
  availableFunctions,
  FnDesc,
  RequiredApprove,
} from "@nested-finance/sdk/web";
import type { Net } from "./config";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { allCollapsedAtom, editorInjectorAtom } from "./atom";
// var compiler = new Worker(new URL('./compiler.ts', import.meta.url));

export interface PlaygroundCfg {
  vaultAddress: HexString;
  net: Net;
}

interface PlaygroundProps extends PlaygroundCfg {
  code: string;
  myAddress: HexString | null;
  codeChange: (code: string) => any;
  onStatusChange?: (result: CompilationResult) => any;
  error: ErrorDef | nil;
}

export type CompilationResult =
  | { type: "compiling" }
  | {
      type: "compiled";
      result: CompiledVaultOperation;
      approvesRequired: RequiredApprove[];
    }
  | ({ type: "compilation failed" } & ErrorDef);

export interface ErrorDef {
  error: string;
  position?: CodePosition;
}
export interface CodePosition {
  from: CodePoint;
  to: CodePoint;
}

export interface CodePoint {
  line: number;
  column: number;
}
type FnTree = FnLeaf | FnNode;
type FnLeaf = { type: "leaf"; name: string; value: FnDesc };
type FnNode = { type: "node"; name: string; children: FnTree[] };

const _fns = availableFunctions();

function buildNode(nodeName: string, fnList: [string, FnDesc][]): FnNode {
  const mapped = fnList.map(([fullName, fn]) => {
    const [, ns, name] = /^(\w+)\.(.+)/.exec(fullName) ?? [
      null,
      null,
      fullName,
    ];
    return {
      ns: ns,
      name,
      fn,
    } as const;
  });

  const lookup = toLookup(mapped, (x) => x.ns ?? null);
  const fns: FnTree[] = [
    ...(lookup.get(null)?.map<FnLeaf>(({ name, fn }) => ({
      type: "leaf",
      name: name!,
      value: fn,
    })) ?? []),
    ...[...lookup.entries()]
      .filter(([k]) => !!k)
      .map(([k, values]) =>
        buildNode(
          k!,
          values.map((x) => [x.name!, x.fn!])
        )
      ),
  ].sort((a, b) => (a.name > b.name ? 1 : -1));

  return { type: "node", name: nodeName, children: fns };
}

export const fns = buildNode(
  "root",
  _fns.map((x) => [x.name, x])
).children;

export function DocTree({ node }: { node: FnTree }) {
  if (node.type === "leaf") {
    return <DocLeaf fn={node.value} />;
  } else {
    return <DocNode node={node} />;
  }
}

function DocLeaf({ fn }: { fn: FnDesc }) {
  const collaspe = useRecoilValue(allCollapsedAtom);
  const injectIntoEditor = useSetRecoilState(editorInjectorAtom);
  const [open, setOpen] = useState(false);

  useEffect(() => setOpen(false), [collaspe]);

  return (
    <div className="flex flex-col gap-4">
      <div
        onClick={() => setOpen(!open)}
        className="cursor-pointer flex p-3 rounded-2xl bg-surface-muted gap-3 items-center"
      >
        <img src={_function} />
        <div className="text-base font-medium flex-1 flex gap-4">
          <span className="flex-1">{fn.name}</span>
          {fn.overloads.length > 1 ? (
            <span className="text-font-variant">
              {fn.overloads.length} overloads
            </span>
          ) : null}
        </div>
        <img src={caret} className={open ? "rotate-180" : ""} />
        <img />
      </div>
      {open && (
        <ul className="flex flex-col pl-4 border-l border-outline gap-4">
          {fn.overloads.map((o) => (
            <li
              key={o.args.join(",")}
              className="rounded-2xl bg-surface-variant-muted p-3 text-sm"
            >
              <div className="text-font-variant whitespace-pre-line">
                {o.desc}
              </div>
              <div className="p-2">
                <span
                  className="text-accent cursor-pointer"
                  onClick={() =>
                    injectIntoEditor?.(
                      `${fn.name}(${o.args.map((it) => it.name).join(", ")});`
                    )
                  }
                >
                  {fn.name}
                </span>
                {!o.args.length ? (
                  "()"
                ) : (
                  <>
                    (<br />
                    {o.args.map((a) => (
                      <span key={a.name}>
                        <span className="ml-5 text-gray-500">// {a.desc}:</span>
                        <br />
                        <span className="ml-5">
                          {a.name}:
                          <span className="text-accent"> {a.type}</span>
                        </span>
                        <br />
                      </span>
                    ))}
                    )
                  </>
                )}
                {!o.args.length ? (
                  <div className="text-gray-500 ml-5"> → {o.returns}</div>
                ) : (
                  <span className="text-gray-500"> → {o.returns}</span>
                )}
              </div>
              {o.offchain && (
                <div className="italic text-gray-500 px-2 whitespace-normal">
                  nb: this is an offchain function, it will be compiled to a
                  constant
                </div>
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

function DocNode({ node }: { node: FnNode }) {
  const collaspe = useRecoilValue(allCollapsedAtom);
  const [open, setOpen] = useState(false);

  useEffect(() => setOpen(false), [collaspe]);

  return (
    <div className="flex flex-col gap-4">
      <div
        onClick={() => setOpen(!open)}
        className="cursor-pointer flex p-3 rounded-2xl bg-surface-muted gap-3 items-center"
      >
        <img src={_package} />
        <span className="text-base font-medium flex-1">{node.name}</span>
        <img src={caret} className={open ? "rotate-180" : ""} />
        <img />
      </div>
      {open && (
        <div className="flex flex-col pl-4 border-l border-outline gap-4">
          {node.children.map((n) => (
            <DocTree key={n.name} node={n} />
          ))}
        </div>
      )}
    </div>
  );
}

export function Editor(props: PlaygroundProps) {
  const [ref, { height }] = useElementSize();
  const valueToInject = useRecoilValue(editorInjectorAtom);

  // typescript is wrong...
  const EE = EEditor as unknown as FC<
    PlaygroundProps & { height: number; valueToInject?: string }
  >;
  return (
    <div className="h-full w-full" ref={ref}>
      <EE {...props} height={height} valueToInject={valueToInject} />
    </div>
  );
}

const editorLanguage = "sol";

interface State {}
type CP = PlaygroundProps & { height: number; valueToInject?: string };
class EEditor extends React.Component<
  PlaygroundProps & { height: number; valueToInject?: string },
  State
> {
  private editor?: monaco.editor.ICodeEditor;
  private monaco?: typeof monaco;
  private hoverProvider?: monaco.IDisposable;
  private timeout: any;
  private code: string;
  constructor(props: CP) {
    super(props);

    this.state = { status: "compiling" };
    // swap0x($USDT, 1000000, $USDC, 3%);
    this.code =
      props.code ||
      dedent`
        #use vault;

        // deposit some usdc from wallet
        deposit(0.01 usdc);
        usdc =  balance($USDC);
        log("My USDC balance is: ", usdc);
        price = 0.95 [usdt/usdc]; // we expect at least 1usdc = 0.95 usdt... but allow 5% slippage
        gotUsdt = dex.uniswapV2(usdc, usdc * price);
        log("... we got usdt: ", gotUsdt);
        withdraw(gotUsdt);

        // If your input is constant, you can also perform swaps via 0x or paraswap...
        //   1) you might get better prices
        //   2) you will avoid non existing liquidity pools
        //   3) the interface is more user-friendly, since you dont have to specify an expected output amount:
        // ex:
        //
        // dex.zerox(1 usdc, $DAI, 3%);
        `;
  }
  editorDidMount(editor: monaco.editor.ICodeEditor, m: typeof monaco) {
    this.editor = editor;
    this.monaco = m;
    editor.focus();

    this.monaco.languages.register({ id: editorLanguage });

    this.compile(this.code);
  }

  onChange(newValue: string) {
    if (newValue === this.code) {
      return;
    }
    this.code = newValue;
    this.props.codeChange(newValue);
    this.recompile();
  }

  componentWillReceiveProps(newProps: CP) {
    if (newProps.code !== this.code && newProps.code) {
      this.code = newProps.code;
      this.editor?.getModel()?.setValue(newProps.code);
    }

    if (
      newProps.valueToInject &&
      this.props.valueToInject !== newProps.valueToInject
    ) {
      const model = this.editor?.getModel();
      const position = this.editor?.getPosition();
      if (model && position) {
        model.pushEditOperations(
          [],
          [
            {
              range: new MonacoRange(
                position.lineNumber,
                position.column,
                position.lineNumber,
                position.column
              ),
              text: newProps.valueToInject,
            },
          ],
          () => null
        );
      }
    }

    this.updateError(newProps.error);
  }

  private updateError(e: ErrorDef | nil) {
    this.monaco?.editor?.setModelMarkers(
      this.editor?.getModel()!,
      "playground",

      e?.position
        ? [
            {
              startColumn: e.position.from.column,
              endColumn: e.position.to.column,
              startLineNumber: e.position.from.line,
              endLineNumber: e.position.to.line,
              message: e.error,
              severity: this.monaco.MarkerSeverity.Error,
            },
          ]
        : []
    );
  }

  recompile() {
    clearTimeout(this.timeout);
    this.setState({
      ...this.state,
      globalError: null,
      status: "compiling",
      bytecode: null,
      resultType: null,
    });
    this.timeout = setTimeout(() => this.compile(this.code), 300);
  }

  async compile(code: string) {
    this.props.onStatusChange?.({ type: "compiling" });
    try {
      const cfg: VaultConfig = {
        vaultAddress: this.props.vaultAddress,
        rpcUrl: this.props.net.rpc,
        excludedDexes: ["Portals"],
      };

      const result = await callVault(cfg, code);
      if (code !== this.code) {
        // concurrency
        return;
      }

      // count approves
      let approvesRequired: RequiredApprove[] = [];
      const ma = this.props.myAddress;
      if (ma) {
        for (const a of result.requiredApproves) {
          if (
            !(await isAllowed(
              a.token,
              ma,
              result.vaultAddress,
              a.knownAmount?.amount
            ))
          ) {
            approvesRequired.push(a);
          }
        }
      }
      if (code !== this.code) {
        // concurrency
        return;
      }

      this.props.onStatusChange?.({
        type: "compiled",
        result,
        approvesRequired,
      });

      // display warnings
      const markers = [];
      for (const m of result.metadata) {
        if (m.tag === "WarningMedata") {
          markers.push({
            message: m.message,
            severity: MarkerSeverity.Warning,
            startLineNumber: m.loc.start.line,
            startColumn: m.loc.start.column,
            endLineNumber: m.loc.end.line,
            endColumn: m.loc.end.column,
          });
        }
      }
      editor.setModelMarkers(this.editor?.getModel()!, "owner", markers);

      // clear old hover provider
      this.hoverProvider?.dispose();

      // register a hover provider to display compiled metadata
      this.hoverProvider = this.monaco?.languages.registerHoverProvider(
        editorLanguage,
        {
          provideHover: function (_, position) {
            const found = result.metadata.find(
              (m) =>
                position.lineNumber === m.loc.start.line &&
                position.column >= m.loc.start.column &&
                position.column <= m.loc.end.column
            );
            if (found) {
              const range = new MonacoRange(
                found.loc.start.line,
                found.loc.start.column,
                found.loc.end.line,
                found.loc.end.column
              );

              switch (found.tag) {
                case "InputSwapMetadata":
                  return {
                    range,
                    contents: [
                      { value: "```ts\ndex: " + found.dex + "\n```" },
                      { value: "```ts\nprice: " + found.price + "\n```" },
                      {
                        value:
                          "```ts\ninputAmount: " +
                          BigInt(found.inputAmount) +
                          "\n```",
                      },
                      {
                        value:
                          "```ts\ninputTokenDecimals: " +
                          found.inputTokenDecimals +
                          "\n```",
                      },
                      {
                        value:
                          "```ts\noutputAmount: " +
                          BigInt(found.outputAmount) +
                          "\n```",
                      },
                      {
                        value:
                          "```ts\noutputTokenDecimals: " +
                          found.outputTokenDecimals +
                          "\n```",
                      },
                    ],
                  };
                case "DeBridgeMetadata":
                  return {
                    range,
                    contents: [
                      {
                        value:
                          "```ts\ninputAmount: " + found.inputAmount + "\n```",
                      },
                      {
                        value:
                          "```ts\nfees: " +
                          found.fees.inputUsedForFixedNativeFee +
                          " (flat) + " +
                          found.fees.inputUsedForMarketMakerFee +
                          " (market maker)\n```",
                      },
                      {
                        value:
                          "```ts\nbridgedInputAmount: " +
                          (found.inputAmount -
                            found.fees.inputUsedForFixedNativeFee -
                            found.fees.inputUsedForMarketMakerFee) +
                          "\n```",
                      },
                      {
                        value:
                          "```ts\nbridgedOutputAmount: " +
                          found.outputAmount +
                          "\n```",
                      },
                      {
                        value:
                          "```ts\nestimatedExecutionDelay: " +
                          found.estimatedExecutionDelay +
                          "s\n```",
                      },
                    ],
                  };
                case "SynthetixV3Metadata":
                  return {
                    range,
                    contents: [
                      {
                        value: "```ts\nmarket: " + found.market + "\n```",
                      },
                      {
                        value:
                          "```ts\nindexPrice: " + found.indexPrice + "\n```",
                      },
                      {
                        value:
                          "```ts\ndesiredFillPrice: " +
                          found.desiredFillPrice +
                          "\n```",
                      },
                      {
                        value:
                          "```ts\nrequiredMargin: " +
                          found.requiredMargin +
                          "\n```",
                      },
                      {
                        value: "```ts\nprevSize: " + found.prevSize + "\n```",
                      },
                      {
                        value: "```ts\nnextSize: " + found.nextSize + "\n```",
                      },
                      {
                        value:
                          "```ts\nmarginFees: " +
                          found.marginFees.order +
                          " (order) + " +
                          found.marginFees.settlement +
                          " (settlement)\n```",
                      },
                    ],
                  };
                case "GmxMetadata":
                  return {
                    range,
                    contents: [
                      {
                        value:
                          "```ts\nacceptablePrice: " +
                          found.acceptablePrice +
                          "\n```",
                      },
                      {
                        value: "```ts\nmarkPrice: " + found.markPrice + "\n```",
                      },
                      {
                        value:
                          "```ts\nentryPrice: " + found.entryPrice + "\n```",
                      },
                      {
                        value:
                          "```ts\nprevEntryPrice: " +
                          found.prevEntryPrice +
                          "\n```",
                      },
                      {
                        value:
                          "```ts\nliquidationPrice: " +
                          found.liquidationPrice +
                          "\n```",
                      },
                      {
                        value:
                          "```ts\nprevLiquidationPrice: " +
                          found.prevLiquidationPrice +
                          "\n```",
                      },
                      { value: "```ts\nleverage: " + found.leverage + "\n```" },
                      {
                        value:
                          "```ts\nprevLeverage: " +
                          found.prevLeverage +
                          "\n```",
                      },
                      {
                        value:
                          "```ts\nmaxExecutionFee: " +
                          found.maxExecutionFee +
                          "\n```",
                      },
                    ],
                  };
                case "MuxMetadata":
                  return {
                    range,
                    contents: [
                      {
                        value:
                          "```ts\nassetPrice: " + found.assetPrice + "\n```",
                      },
                      {
                        value:
                          "```ts\npositionValueUsd: " +
                          (found.prev
                            ? found.prev.positionValueUsd +
                              " -> " +
                              found.next.positionValueUsd
                            : found.next.positionValueUsd) +
                          "\n```",
                      },
                      {
                        value:
                          "```ts\nsize: " +
                          (found.prev
                            ? found.prev.subAccount.size +
                              " -> " +
                              found.next.subAccount.size
                            : found.next.subAccount.size) +
                          "\n```",
                      },
                      {
                        value:
                          "```ts\nleverage: " +
                          (found.prev
                            ? found.prev.leverage + " -> " + found.next.leverage
                            : found.next.leverage) +
                          "\n```",
                      },
                      {
                        value:
                          "```ts\nentryPrice: " +
                          (found.prev
                            ? found.prev.subAccount.entryPrice +
                              " -> " +
                              found.next.subAccount.entryPrice
                            : found.next.subAccount.entryPrice) +
                          "\n```",
                      },
                      {
                        value:
                          "```ts\nliquidationPrice: " +
                          (found.prev
                            ? found.prev.liquidationPrice +
                              " -> " +
                              found.next.liquidationPrice
                            : found.next.liquidationPrice) +
                          "\n```",
                      },
                      {
                        value:
                          "```ts\ncollateral: " +
                          (found.prev
                            ? found.prev.subAccount.collateral +
                              " -> " +
                              found.next.subAccount.collateral
                            : found.next.subAccount.collateral) +
                          "\n```",
                      },
                      {
                        value:
                          "```ts\nwithdrawableCollateral: " +
                          (found.prev
                            ? found.prev.withdrawableCollateral +
                              " -> " +
                              found.next.withdrawableCollateral
                            : found.next.withdrawableCollateral) +
                          "\n```",
                      },
                      {
                        value:
                          "```ts\ntotalFeeUsd: " +
                          found.feeUsd +
                          " (feeUsd) + " +
                          (found.prev?.fundingFeeUsd ||
                            found.next.fundingFeeUsd) +
                          " (fundingFeeUsd)\n```",
                      },
                    ],
                  };
              }
            }

            return undefined;
          },
        }
      );
    } catch (e) {
      let msg = (e as any).message as string;
      const [ok, begin, fromLine, fromCol, toLine, toCol] =
        /^(.+)\s+at\s+\((\d+):(\d+)\s+->\s+(\d+):(\d+)\)$/.exec(msg) ?? [];
      let position: CodePosition | undefined = undefined;
      if (ok) {
        position = {
          from: { line: parseInt(fromLine), column: parseInt(fromCol) },
          to: { line: parseInt(toLine), column: parseInt(toCol) },
        };
        msg = begin;
      }
      this.props.onStatusChange?.({
        type: "compilation failed",
        error: msg,
        position,
      });
    }
  }

  render() {
    const options = {
      selectOnLineNumbers: true,
      glyphMargin: true,
    };
    // typescript is wrong...
    const ME = MonacoEditor as unknown as FC<MonacoEditorProps>;
    return (
      <div className="rounded-2xl p-3 bg-[#1e1e1e]">
        <ME
          width="100%"
          height={this.props.height - 24}
          language={editorLanguage}
          theme="vs-dark"
          value={this.code}
          options={options}
          onChange={this.onChange.bind(this)}
          editorDidMount={this.editorDidMount.bind(this)}
        />
      </div>
    );
  }
}
